.footer {
  background: var(--panel-bg) !important;
  border-color: var(--grid-col-border);

  .slogan-wrapper {
    .slogan {
      color: var(--footer-slogan);
    }
    .text {
      color: var(--footer-text);
    }
  }
  .tenant-wrapper {
    img {
      height: 45px;
    }
  }
}
