#sidebar {
    font-weight: bold;
    background: var(--sidebar-bg);

    .sidebar-brand {
        background: var(--sidebar-bg);
    }

    .sidebar-nav {
        .nav-group:has(.nav-link.active) .nav-group-toggle {
            svg {
                color: #ffffff;
            }
        }

        .nav-link.active {
            font-weight: bold;

            svg {
                color: #ffffff;
            }

            c-sidebar-nav-link-content {
                color: #ffffff;
            }
        }
        .nav-icon{
            color: var(--sidebar-icon-color);
        }
    }

    .sidebar-toggle-logo-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: end;

        .side-bar-logo-wrap {
            text-align: center;
            margin-top: 20px;
            padding-left: 5px;
            padding-bottom: 8px;

            img {
                width: 50px;
            }
        }
    }

}