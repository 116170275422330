/*
$primaryFont: "Montserrat", sans-serif;
*/

$dark-logo: invert(1) brightness(20);
$light-logo: inherit;

$danger-color: #FF8B8B;

$dark-color: #000;
$light-color: #fff;

$dark-panel-bg: #161719;
$light-panel-bg: #F8F8FD;

$light-db-btn-bg: #505d7c;
$dark-db-btn-bg: #232634;

$light-db-btn-text: #fff;
$dark-db-btn-text: #fff;

$light-db-btn-border: unset;
$dark-db-btn-border: #45516D;
$light-dropdown-border: #d3d3d3;

$dark-grid-bg: #20222C;
$dark-inner-bg: #232634;
$light-grid-bg: #FFF;
$dark-btn-arrow: #292E40;
$dark-grid-row: #313A51;
$light-grid-row: #EAEDF8;
$dark-grid-column: #BCC3D8;
$light-grid-column: #505D7C;
$dark-grid-border: transparent;
$light-grid-border: #DEE2EE;
$dark-grid-column-border: #45516D;
$dark-page-number: #A5ACC0;
$light-page-number: #565656;

$dark-input-border: #A5ACC0;
$light-input-border: #DDE6FC;
$dark-input-background: #A5ACC0;
$light-input-background: #FCFDFF;

$dark-button-filter: #A5ACC0;
$light-button-filter: #505D7C;
$dark-filter-icons: #161719;
$light-filter-icons: #0689FF;
$dark-filter-bg: #292E40;
$light-filter-bg: #FAFBFF;

$dark-tabs-icon-bg: #313A51;
$light-tabs-icon-bg: #E5EEFD;
$light-sidebar-icon-color: #E5EEFD;
$dark-tabs-icon-color: #2196f3;
$light-tabs-icon-color: #044FBF;
$dark-tabs-text: #DEE2EE;
$light-tabs-text: #505D7C;
$dark-tabs-border: #232634;
$light-tabs-border: #DEE2EE;

$dark-dropdown-bg: #232634;
$light-dropdown-bg: #FCFDFF;

$dark-date-color: #fff;
$light-date-color: #505D7C;

$dark-blue-icon: #fff;
$light-blue-icon: #0689FF;

$dark-sidebar-bg: #232634;
$light-sidebar-bg: #16487a; // #505D7C

$dark-hover-bg: #45516D;
$light-hover-bg: #e8ebf3;

$light-footer-slogan: #333;
$light-footer-text: #9e9e9e;
$dark-footer-slogan: #ccc;
$dark-footer-text: #607d8b;

$manageRecordColor: #c90c0c26;

$opacity: 0.6;

$sidebar: #16487a;
$sidebarMenuActive: #1b3d60;
$dashboardBackground: #eee;

$primary: #25578a;
$secondary: #112033;
$error: #dc3545;
$success: #238636;
$warning: #ffc107;
$borderColor: #e4e4e4;
$labelColor:#53565a;
$info: #0dcaf0;
$light: #f8f9fa;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "danger": $error,
  "success": $success,
  "warning": $warning,
  "info": $info,
  "light": $light,
);

// Defining base font size
$base-size: 16;

// font family
$primaryFont: "Montserrat", sans-serif;

[data-bs-theme="light"] {
  --badge-bg: #{$light-panel-bg};
  
  --panel-bg: #{$light-panel-bg};
  --light-color: #{$light-color};
  --dark-color: #{$dark-color};
  --light-button-background: #{$light-button-filter};
  
  --db-btn-bg: #{$light-db-btn-bg};
  --db-btn-text: #{$light-db-btn-text};
  --db-btn-border: #{$light-db-btn-border};
  
  --grid-bg: #{$light-grid-bg};
  --grid-inner-bg: #{$light-grid-bg};
  --grid-filter-bg: #{$light-filter-bg};
  --grid-row-bg: #{$light-grid-row};
  --grid-arrow-bg: #{$light-grid-row};
  --grid-column-color: #{$light-grid-column};
  --grid-border: #{$light-grid-border};
  --grid-col-border: #{$light-grid-border};
  --grid-page-number: #{$light-page-number};
  
  --tab-text: #{$light-tabs-text};
  --tab-icon-color: #{$light-tabs-icon-color};
  --sidebar-icon-color: #{$light-sidebar-icon-color};
  --tab-icon-bg: #{$light-tabs-icon-bg};
  --tab-border: #{$light-tabs-border};
  
  --dropdown-bg: #{$light-dropdown-bg};
  --dropdown-border: #{$light-dropdown-border};
  --hover-bg: #{$light-hover-bg};
  --date-color: #{$light-date-color};
  --date-icon: #{$light-blue-icon};
  --logo: #{$light-logo};
  
  --footer-slogan: #{$light-footer-slogan};
  --footer-text: #{$light-footer-text};
  
  --sidebar-bg: #{$light-sidebar-bg};
  --danger-color: #{$danger-color};
  --success-color: #{$success};
}

[data-bs-theme="dark"] {
  --badge-bg: #{$dark-panel-bg};
  
  --panel-bg: #{$dark-panel-bg};
  --dark-color: #{$light-color};
  --light-color: #{$dark-color};
  --light-button-background: #{$dark-button-filter};
  
  --db-btn-bg: #{$dark-db-btn-bg};
  --db-btn-text: #{$dark-db-btn-text};
  --db-btn-border: #{$dark-db-btn-border};
  
  --grid-bg: #{$dark-grid-bg};
  --grid-inner-bg: #{$dark-inner-bg};
  --grid-filter-bg: #{$dark-filter-bg};
  --grid-row-bg: #{$dark-grid-row};
  --grid-arrow-bg: #{$dark-btn-arrow};
  --grid-column-color: #{$dark-grid-column};
  --grid-border: #{$dark-grid-border};
  --grid-col-border: #{$dark-grid-column-border};
  --grid-page-number: #{$dark-page-number};
  
  --tab-text: #{$dark-tabs-text};
  --tab-icon-color: #{$dark-tabs-icon-color};
  --sidebar-icon-color: #{$dark-tabs-icon-color};
  --tab-icon-bg: #{$dark-tabs-icon-bg};
  --tab-border: #{$dark-tabs-border};
  
  --dropdown-bg: #{$dark-dropdown-bg};
  --dropdown-border: #{$dark-db-btn-border};
  --hover-bg: #{$dark-hover-bg};
  --date-color: #{$dark-date-color};
  --date-icon: #{$dark-blue-icon};
  --logo: #{$dark-logo};
  
  --footer-slogan: #{$dark-footer-slogan};
  --footer-text: #{$dark-footer-text};
  
  --sidebar-bg: #{$dark-sidebar-bg};
  --danger-color: #{$danger-color};
  --success-color: #{$success};
}
