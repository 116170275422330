//::ng-deep {
//  .ng-select {
//
//  }
//}

.ng-select.ng-select-disabled {
  pointer-events: none;
  opacity: .5;
}

.ng-select:not(.ng-select-opened) {
  &:hover {
    .ng-select-container {
      //border-color: var(--all-dropdown-border-color);
    }
  }

  .ng-select-container {
    //border: none;
    border-radius: 48px;
    background: var(--dropdown-bg);
    border-color: var(--dropdown-border);

    .ng-value-container {
      .ng-placeholder {
        color: var(--date-color);
      }
    }
  }
}

.ng-select {
  &.header-dropdown {
    .ng-select-container {
      border: 1px solid var(--dropdown-border) !important;
    }
  }

  &.ng-select-opened {
    .ng-select-container {
      //border: none;
      background: var(--dropdown-bg);
      border-color: var(--dropdown-border);

      .ng-value-container {
        .ng-placeholder {
          color: var(--date-color);
        }


      }
    }
  }

  .ng-select-container {

    .ng-value-container {
      .ng-value {
        color: var(--date-color);

        .ng-value-label {
          color: var(--date-color);
        }
      }
    }
  }

  .ng-dropdown-panel {
    border-color: var(--dropdown-border);

    .ng-dropdown-panel-items {
      .ng-option {
        background: var(--dropdown-bg);
        color: var(--dark-color);

        &:hover {
          background-color: var(--hover-bg);
          color: var(--dark-color);
        }

        &.ng-option-selected {
          background-color: var(--hover-bg);
          color: var(--dark-color);
        }

        &.ng-option-marked {
          background-color: var(--hover-bg);
          color: var(--dark-color);
        }
      }
    }
  }
}

.ng-select.default-radius {
  border-radius: 4px;
}

.ng-select.default-radius .ng-select-container {
  border-radius: 4px;
}