#Layer_1,
#svg-dashboard,
#svg-log,
#svg-email,
#svg-tags,
#svg-recipient,
#svg-environment,
#svg-setting,
#svg-service,
#svg-hamburger,
#svg-plus,
#svg-cancel,
#svg-trash,
#svg-edit,
#svg-user {
  width: 18px;
  height: 18px;
}