.custom-secondary-btn {
  background: #fff;
  color: #505d7c;
  border-color: #505d7c;
  border-radius: 48px !important;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 600;
}

.custom-primary-btn {
  background: #505d7c;
  color: #fff;
  border-color: #505d7c;
  border-radius: 48px !important;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 600;
}

.btn-bg-grid-header {
  color: var(--bs-nav-link-color);
  background: none;
}

.custom-icon-button {
  background: #505d7c;
  color: #fff;
}

.card {
  &.body-wrapper {
    background: var(--grid-bg);
  }
}

.table {
  thead {
    tr {
      border-color: var(--grid-col-border) !important;

      th {
        background: var(--grid-row-bg);
        color: var(--dark-color);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding-top: 15px;
        padding-bottom: 15px;
        border: 1px solid;
        border-color: var(--grid-col-border) !important;
      }
    }
  }

  tbody {
    tr {
      border-color: var(--grid-col-border);

      td {
        color: var(--grid-column-color);
        background: var(--grid-bg);
        --bs-table-bg-type: inherit !important;
        // border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;
        border-left: 0;
        border-right: 0;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        vertical-align: middle;
      }
    }
  }

  tfoot {
    tr {
      border: none;

      td {
        border: none;
      }
    }
  }
}

.dx-overlay-wrapper {
  .dx-loadpanel-content {
    background: var(--grid-row-bg);
    border-color: var(--grid-col-border);

    .dx-loadindicator-icon {
      .dx-loadindicator-segment {

        background: var(--dark-color);
      }
    }

    .dx-loadpanel-message {
      color: var(--dark-color);
    }
  }
}

.dx-datagrid-pager {
  // show pagination on top
  margin-top: -1px;
  order: -1 !important;
  border-top: 0 !important;
  padding: 14px 10px 10px;

  .dx-page-sizes {
    .dx-selectbox {
      background: none;

      .dx-texteditor-input-container {
        .dx-texteditor-input {
          color: var(--grid-page-number);
        }
      }

      .dx-texteditor-buttons-container {
        .dx-dropdowneditor-icon {
          &::before {
            color: var(--grid-page-number);
          }
        }
      }
    }

    .dx-page-size {
      padding: 4px 9px 4px 10px;
      color: var(--grid-page-number);

      &.dx-selection {
        background: none;
        border: 1px solid #ccc;
      }
    }
  }

  .dx-pages {
    .dx-info {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 11px;
      color: var(--grid-page-number);
    }

    .dx-page {
      padding: 4px 12px 4px;
      color: var(--grid-page-number);

      &.dx-selection {
        background: none;
        border: 1px solid #ccc;
      }
    }

    .dx-page-indexes {
      .dx-page-index {
        background: none;

        .dx-texteditor-input {
          color: var(--grid-page-number);
        }
      }

      .dx-navigate-button {
        font-size: 16px;
        color: var(--grid-page-number);
      }
    }
  }
}

.dx-datagrid {
  background: var(--grid-bg);

  .dx-datagrid-header-panel {
    .dx-toolbar {
      background: none;

      .dx-datagrid-search-panel {
        background: var(--dropdown-bg);
        border-color: var(--dropdown-border);
        border-radius: 48px;

        .dx-texteditor-input {
          color: var(--date-color);

        }
      }

      .dx-icon-search {
        &::before {
          color: var(--date-icon);
        }
      }

      .dx-show-clear-button {
        .dx-icon-clear {
          color: var(--date-icon);
        }
      }
    }
  }
}

.dx-datagrid-headers {
  border: none !important;

  .dx-header-row {
    background: var(--grid-row-bg);

    td {
      padding-top: 15px;
      padding-bottom: 15px;
      color: var(--dark-color);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border-color: var(--grid-col-border) !important;
    }
  }

  .dx-datagrid-filter-row {
    background: var(--grid-bg);

    td {
      padding-top: 10px;
      padding-bottom: 10px;
      border-color: var(--grid-col-border) !important;

      .dx-editor-with-menu {
        .dx-menu-items-container {
          .dx-item {

            &.dx-state-hover {
              background: var(--grid-bg);
            }

            &.dx-menu-item-expanded {
              background: var(--grid-bg);
            }

            .dx-item-content {
              .dx-icon {
                color: #BCC3D8;
                font-size: 18px;
              }
            }
          }
        }
      }

      .dx-editor-container {
        .dx-texteditor-container {
          .dx-texteditor-input-container {
            .dx-texteditor-input {
              background: var(--grid-bg);
              color: var(--grid-column-color);
              border-radius: 0;
            }
          }
        }

        .dx-texteditor-buttons-container {
          .dx-widget {
            background: var(--grid-bg);
            color: var(--grid-column-color);
            border-radius: 0;

            .dx-button-content {
              .dx-dropdowneditor-icon {
                background: var(--grid-bg);

                &::before {
                  color: #BCC3D8;
                }
              }
            }
          }
        }
      }
    }
  }
}

.dx-popup-wrapper {
  .dx-overlay-content {
    background: var(--dropdown-bg);

    .dx-calendar {
      background: var(--dropdown-bg);

      .dx-calendar-body {
        .dx-calendar-views-wrapper {
          table {
            tr {
              td {
                &.dx-calendar-cell {
                  color: var(--dark-color);

                  &.dx-calendar-other-view {
                    opacity: 0.5;
                  }
                }
              }
            }
          }
        }
      }

      .dx-calendar-navigator {
        .dx-button {
          background: var(--bs-body-bg);

          .dx-button-content {
            color: var(--bs-body-color);
          }
        }
      }
    }
  }
}

.dx-datagrid-rowsview {
  border: none !important;

  .dx-datagrid-content {
    .dx-datagrid-table-fixed {
      .dx-row {
        background: var(--grid-bg);

        button {
          &.rounded {
            background: var(--grid-arrow-bg);
            color: var(--grid-column-color);
          }
        }

        .dx-master-detail-cell {
          background: var(--grid-inner-bg);
        }

        .dx-row {
          .dx-master-detail-cell {
            background: var(--grid-inner-bg);
          }
        }

        .dx-command-edit {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .dx-data-row {
        border-color: var(--grid-col-border);

        .dx-template-wrapper {
          .ng-star-inserted {
            .ng-star-inserted {
              background: var(--grid-row-bg);
              color: var(--dark-color);
            }
          }
        }

        td {
          border-left: 0;
          border-right: 0;
          padding-top: 10px;
          padding-bottom: 10px;
          color: var(--grid-column-color);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          vertical-align: middle;
        }
      }
    }
  }
}

.dx-overlay-wrapper {
  .dx-submenu {
    background: var(--grid-bg);
    border-color: var(--grid-col-border);

    .dx-menu-item {
      &.dx-menu-item-selected {
        background: var(--grid-column-color);

        .dx-item-content {
          .dx-icon {
            color: var(--grid-bg);
          }

          .dx-menu-item-text {
            color: var(--grid-bg);
          }
        }
      }

      &:hover {
        background: var(--grid-column-color);

        .dx-item-content {
          .dx-icon {
            color: var(--grid-bg);
          }

          .dx-menu-item-text {
            color: var(--grid-bg);
          }
        }
      }

      .dx-item-content {
        .dx-icon {
          color: var(--grid-column-color);
        }

        .dx-menu-item-text {
          color: var(--grid-column-color);
        }
      }
    }
  }
}

button {
  #Layer_1 {
    width: rem(15);
    height: rem(15);

    * {
      stroke: white;
      stroke-width: 3;
      fill: white;
    }
  }
}