.modal-content {
    background: var(--grid-bg);

    .modal-body {
        input {
            color: var(--date-color);
            background: var(--dropdown-bg);
            border-color: var(--dropdown-border);
        }
        .form-check-input{
            &:checked{
                background: #2196f3;
            }
        }

        textarea {
            color: var(--date-color);
            background: var(--dropdown-bg);
            border-color: var(--dropdown-border);

        }
    }
}