.header {
    &.header-sticky {
        background: var(--light-color);
        border: none;

        .nav-hamburger-icon {
            svg {
                color: var(--dark-color);
            }
        }

        .header-nav {
            .nav-link {
                color: var(--date-color);
            }
        }


        .profile-avatar-dropdown {
            .avatar {
                border-radius: 50%;
                width: 45px;
                height: 45px;
                object-fit: cover;
            }

            i {
                bottom: 0;
                left: 42px;
            }

            .dropdown {
                a {
                    color: var(--dark-color);
                }
            }
        }
    }
}

.cursor {
    cursor: pointer !important;
}

.flag-logo {
    max-width: 13%;
    max-height: 7%;
    float: right;
}

.avatar-img {
    max-height: 40px !important;
}