@import "node_modules/animate.css/animate.min.css";
@import "node_modules/hover.css/css/hover-min.css";
@import "node_modules/colors.css/css/colors.min.css";
@import "node_modules/@ng-select/ng-select/themes/default.theme.css";
@import 'node_modules/ngx-toastr/toastr';
@import 'node_modules/three-dots/dist/three-dots.css';

/* core-ui library (already imported in angular.json) */
// @import "_coreui/index";

/* bootstrap library (already imported in angular.json) */
// @import "_bootstrap/index";

@import "./component/index";

@import "general";
