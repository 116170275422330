@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

@media (min-width: 1400px) {
  .container-xxl {
    max-width: 1500px;
  }
}

body {
  font-family: "Roboto", sans-serif;
}

.required:after {
  content: " *";
  color: red;
}

.cursor {
  cursor: pointer !important;
}

.bg-light {
  background: var(--panel-bg) !important;
}

.monaco-editor {
  display: block;
  outline: none;
  height: calc(100vh - 100px);
  .editor-container {
    height: calc(100vh - 100px);
  }
}

.circle {
  width: 50px;
  height: 50px;
  background: lightcoral;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
}

.is-shared-false {
  background-color: #e6f5e9 !important;
}

.is-shared-true-background {
  background-color: #e6f5e9;
  color: #0ab730;
}

.is-shared-false-background {
  background-color: #f5eccd;
  color: #ab9960;
}

.is-shared-true-icon {
  color: #0ab730;
}

.is-shared-false-icon {
  color: #e6f5e9 !important;
}

.is-shared-true {
  border: none !important;
  background-color: #91ff24 !important;
  outline: none !important;
}

.form-switch-style {
  font-size: 14 !important;
  cursor: pointer;
  color: #f1f2f2 !important;
  outline: none !important;

  &:hover {
    outline: none !important;
    color: #f1f2f2 !important;
  }
  &:before {
    outline: none !important;
    color: #f1f2f2 !important;
  }
}

.card-footer > div {
  width: 43%;
}

.search-div {
  button {
    color: #148fff;
    background-color: #e5eefd;
    padding: 6px;
  }
}

.not-enabled-config {
  background-color: grey;
  color: #f5f5f5;

  span {
    background-color: grey;
    color: #fff;
    margin-top: 5px;
  }
  a {
    color: #000;
  }
}

.not-target-config {
  background-color: lightgray;
  color: #000;

  span {
    background-color: lightgray;
    color: #000;
    margin-top: 5px;
  }
  a {
    color: #000;
  }

  .dropdown-menu > li > a{
    color: #000;
  }

}

.publisher-dropdown-create-configuration{
  margin-top: -8px;
  }

.verified-true {
  background-color: #2a7a2a;
  color: #fff;

  span {
    margin-top: 5px;
  }
  a {
    color: #fff;

  }
  .dropdown-menu > li > a{
    color: #000;
  }
}

.verified-false {
  background-color: #dc3545;
  color: #fff;
  span {
    margin-top: 5px;
  }
  a {
    color: #fff;
  }
  .dropdown-menu > li > a{
    color: #000;
  }
}

.copy-label{
  margin-bottom: 0 !important;
}

.offcanvas-end {
  width : 90% !important;
  overflow-y: scroll !important;
}

.health-refresh-button{
  float: right;
}
